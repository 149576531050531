import React from "react";
import { Paper, Grid, IconButton, Checkbox, FormControlLabel} from "@material-ui/core";
import { Search, SaveAlt, Refresh } from "@material-ui/icons";
import { useSelector } from "react-redux";
import { MInput, MSelect, MKeyBoardDatepicker, MButton } from "../form";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import { makeStyles } from "@material-ui/styles";
import { getExportActivity } from "../../services/exportActivity";
import { convDate } from "../../helpers";
import { Modal,Button } from "@mui/material";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { EXPORT_ACTIVITY_STATE } from "../../redux/exportActivity/exportActivity.types";
import { useDispatch } from "react-redux";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px"
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      paper: {
        paddingTop: 2,
        paddingBottom: 12,
        paddingLeft: 18,
        paddingRight: 24,
        color: theme.palette.text.secondary,
        marginBottom: 36,
        borderRadius: 18,
        boxShadow: 'none',
      },
      formControl: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(2),
        minWidth: 120,
      },
      title: {
        lineHeight: 0,
        marginBottom: 30,
        fontSize: '1.8em',
      },
      tableHead: {
        fontWeight: 'bold',
      },
      tableTitle: {
        fontSize: '18px',
        fontWeight: '700',
        color: '#656464',
        fontFamily: 'Poppins !important',
        marginTop: '-2px !important',
        marginLeft: '-8px !important',
      },
      tableTooltip: {
        fontSize: '14px',
      },
      checkInTrue: {
        color: '#89d300',
        fontSize: 20,
      },
      checkInFalse: {
        color: '#FFCC00',
        fontSize: 20,
      },
      tableHeaderIcon: {
        fontSize: 30,
      },
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      modalTitle: {
        marginBottom: 30,
        textalign: 'center',
        fontWeight: 600,
      },
      fade: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2),
        width: 600,
      },
      modalItemSpacing: {
        margin: theme.spacing(1),
      },
      bold: {
        fontWeight: 'bold',
        color: 'black',
      },
      modalAvatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
      },
      modalCloseBtn: {
        float: 'right',
        marginBottom: 10,
      },
      dialogContent: {
        padding: 20,
      },
      cardColor: {
        backgroundColor: '#ffffff',
        width: '70%',
      },
      imgSize: {
        width: 128,
        height: 128,
      },
      modalStyle1: {
        overflow: 'scroll',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      filterBtn: {
        'marginTop': '25px',
        'marginRight': '20px',
        'backgroundColor': '#00AFF0',
        'boxShadow': 'none',
        '&:hover': {
          backgroundColor: '#00A2DE',
          boxShadow: 'none',
        },
        'color': '#FFFFFF',
      },
      filterBtnEnd: {
        marginTop: 25,
        marginRight: 0,
      },
      filter: {
        marginRight: 25,
      },
      btnFilterContainer: {
        display: 'inline-flex',
      },
      filterDatePicker: {
        marginRight: 25,
        maxWidth: 150,
      },
      detailBtn: {
        'backgroundColor': '#00AFF0',
        'color': '#fff',
        'boxShadow': 'none',
        '&:hover': {
          backgroundColor: '#00A2DE',
          boxShadow: 'none',
        },
      },
      searchIcon: {
        position: 'absolute',
        right: 0,
      },
      btnExport: {
        'backgroundColor': '#00AFF0',
        'boxShadow': 'none',
        '&:hover': {
          backgroundColor: '#00A2DE',
          boxShadow: 'none',
        },
        'color': '#fff',
      },
      tableImg: {
        height: 50,
        width: 50,
      },
      title: {
        lineHeight: 0,
        marginBottom: 30,
        fontSize: '1.8em',
      },
      accordionTitle: {
        fontSize: 14,
        fontWeight: '600',
        color: '#656464',
      },
      accordionValue: {
        fontSize: 14,
        fontWeight: '700',
        color: '#00A2DE',
      },
      statusRequest: {
        fontSize: 14,
        fontWeight: '700',
        color: '#00A2DE',
      },
      statusOngoing: {
        fontSize: 14,
        fontWeight: '700',
        color: '#FFC226',
      },
      statusRejected: {
        fontSize: 14,
        fontWeight: '700',
        color: '#F00B38',
      },
      statusCompleted: {
        fontSize: 14,
        fontWeight: '700',
        color: '#008000',
      },
      timelineDate: {
        fontSize: 12,
        fontWeight: '400',
        color: '#737373',
      },
      informationTitle: {
        fontSize: 16,
        fontWeight: '700',
        color: '#656464',
        marginBottom: 8,
      },
      informationDetail: {
        fontSize: 14,
        fontWeight: '400',
        color: '#656464',
      },
      tableHead: {
        fontWeight: 'bold',
      },
      accordionDetail: {
        fontSize: 14,
        fontWeight: 400,
        color: '#656464',
      },
      historyText: {
        fontSize: 16,
        fontWeight: 700,
        color: '#656464',
      },
  }));

function RenderGlobalFilter(props) {
    let classes = useStyles()
    let dispatch = useDispatch()
    const { exportLoading } = useSelector((state) => state.attendanceHistory);

    const { query, 
      handleSearch, 
      handleFilterChange, 
      handleFilterCheckbox, 
      handleFilterDateChange, 
      handleExport, renderMethodOptions, 
      handleButtonFilter, 
      handleReset,
      disabledExport, 
      filterCheckbox, 
      startDate, 
      endDate, 
      errorFilterDate, 
      redirectToExportActivity,
      method } = props;

      const [open, setOpen] = React.useState(false);


      const handleClose = () => {
        setTimeout(() => {
          console.log('kepanggil');
          redirectToExportActivity()
          setOpen(false)
        }, 300);
      };


      const checkboxTheme = () => createTheme({
          palette: {
            primary: {
              main: '#00AFF0',
            },
          },
        })

      const formatDate = (start, end) => {
        const formattedStart = start.replaceAll("-", "");
        const formattedEnd = end.replaceAll("-", "");
        return `${formattedStart}_${formattedEnd}`;
      };

      const handleValidatedSameData = async() => {
        const date = formatDate(convDate(startDate), convDate(endDate));
        const params = {
            page: 1,
            limit: 10,
            order: 'desc',
            query: date,
            method: method
        };
        

        let response = await getExportActivity(params);
        if(response.data.data && response.data.data.length > 0){
          setOpen(true)
          dispatch({type:EXPORT_ACTIVITY_STATE.EXISTING_EXPORT_ACTIVITY, data:response.data.data});
        }else{
          handleExport()
        }
      }
  
  return (
    <>
    <Paper variant="outlined" className={classes.paper}>
      {filterCheckbox ? (
        <>
          <MInput
            autoFocus
            className={`${classes.filter} ${classes.searchInput}`}
            fullWidth={false}
            variant="standard"
            name="query"
            label="Search"
            placeholder="Search keyword"
            value={query}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <IconButton position="end">
                  <Search className={classes.searchIcon} />
                </IconButton>
              ),
            }}
          />
          <ThemeProvider theme={checkboxTheme()}>
            <FormControlLabel
            style={!filterCheckbox ?{marginTop: 22} : {marginRight: 24, marginTop: 22}}
              control={
                <Checkbox
                  color="primary"
                  checked={filterCheckbox}
                  onChange={handleFilterCheckbox}
                />
              }
              label="Filter"
            />
          </ThemeProvider>
          <MSelect
              shrink={true}
              fullWidth={false}
            classNameFC={`${classes.formControl} ${classes.filter}`}
            variant="standard"
            name="method"
            label="Type"
            keyPair={['id', 'name']}
            options={renderMethodOptions()}
            value={method}
            onChange={handleFilterChange}
          />
          <MKeyBoardDatepicker
          className={classes.filterDatePicker}
            name="startDate"
            label="Start Date"
            value={startDate}
            onDateChange={handleFilterDateChange}
            placeholder="dd-mm-yyyy"
            maxDate={endDate ? new Date(endDate) : undefined}
            error={
              startDate === null && endDate !== null ? 'Start Date is required' :
                startDate === null && errorFilterDate ? 'Start Date is required' :
                  undefined
            }
          />
          <MKeyBoardDatepicker
          className={classes.filterDatePicker}
            name="endDate"
            label="End Date"
            value={endDate}
            onDateChange={handleFilterDateChange}
            placeholder="dd-mm-yyyy"
            minDate={new Date(startDate)}
            error={
              startDate !== null && endDate === null ? 'End Date is required' :
                endDate === null && errorFilterDate ? 'End Date is required' :
                  undefined
            }
          />
          <div className={classes.btnFilterContainer}>
              <MButton
                className={classes.filterBtn}
                label="Apply"
                color="primary"
                onClick={() =>handleButtonFilter()}
              />
              <MButton
                className={classes.filterBtn}
                label="Reset"
                color="primary"
                icon={<Refresh/>}
                onClick={()=>handleReset()}
              />
          <MButton 
            className={`${classes.btnExport} ${classes.filterBtnEnd}`}
            label="Export" 
            icon={<SaveAlt />} 
            onClick={()=>handleValidatedSameData()} 
            loading={exportLoading} 
            disabled={disabledExport}/>
            </div>
        </>
      ) :           
        <Grid container justifyContent='space-between'>
            <Grid item>
                    <MInput
                      autoFocus
                      className={`${classes.filter} ${classes.searchInput}`}
                      fullWidth={false}
                      variant="standard"
                      name="query"
                      label="Search"
                      placeholder="Search keyword"
                      value={query}
                      onChange={handleSearch}
                      InputProps={{
                        endAdornment: (
                          <IconButton position="end">
                            <Search className={classes.searchIcon} />
                          </IconButton>
                        ),
                      }}
                    />
                    <ThemeProvider theme={checkboxTheme()}>
                      <FormControlLabel
                        style={!filterCheckbox ?{marginTop: 22} : {marginRight: 24, marginTop: 22}}
                        control={
                          <Checkbox
                            color='primary'
                            checked={filterCheckbox}
                            onChange={(e) => handleFilterCheckbox(e)}
                          />
                        }
                        label="Filter"
                      />
                    </ThemeProvider>
                  </Grid>
                  <Grid item>
                    <div className={classes.btnFilterContainer}>
                      <MButton
                        className={`${classes.btnExport} ${classes.filterBtnEnd}`}
                        label="Export"
                        icon={<SaveAlt/>}
                        onClick={()=>handleExport()} 
                        loading={exportLoading}
                        disabled={disabledExport}
                      />
                      <br />
                    </div>
                  </Grid>
                </Grid>}
    </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Export Activity
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            This data has already been exported,
            Please check your export activity!.
          </Typography>
          <MButton
           label="Oke"
            color="primary"
            onClick={() =>handleClose()}
            className={classes.filterBtn}
          />
        </Box>
      </Modal>
    </>
  );
}

export default RenderGlobalFilter;
